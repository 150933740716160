<template>
  <div class="py-1 px-2 rounded-md" :style="{ background : event?.tipo.color }" >
    <div class="d-flex align-center">
      <v-icon small left>
        {{ event?.tipo?.icon }}
      </v-icon>
      <h6 class="subtitle-1 font-weight-bold text--white">
        {{ event?.tipo?.nombre }}
      </h6>
      <v-spacer></v-spacer>
      <h3 class="text-subtitle text--white" v-if="idEstudio != event.idEstudio">
        {{ event.idEstudio }}
      </h3>
      <!-- <v-icon small>{{ event.icon }}</v-icon> -->
    </div>
    <div class="d-flex align-center">
      <span>{{ horaInicio }}</span>
      <span class="mx-2"> - </span>
      <span>{{ horaFin }}</span>
      <v-spacer></v-spacer>
      <v-icon v-if="event.completado" small>mdi-check</v-icon>
    </div>
    <div>
      {{ event.estudio?.cups_principal?.direccion }}
    </div>
    <!-- <span>{{ event.desc }}</span> -->
    <!-- <v-icon class="float-right">mdi-check</v-icon> -->
  </div>
</template>

<script>
export default {
  props: {
    event: Object,
    idEstudio: Number | String,
  },
  methods: {
    isSameDay(d1, d2) {
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    },
  },
  computed: {
    horaInicio() {
      const date = new Date(this.event.start);
      const sameDay = this.isSameDay( new Date(this.event.end), date );
      return ` ${!sameDay ? `${date.getDate()}/${(date.getMonth()+1)}` : '' } ${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`
    },
    horaFin() {
      const date = new Date(this.event.end);
      const sameDay = this.isSameDay( new Date(this.event.start), date );
      return ` ${!sameDay ? `${date.getDate()}/${(date.getMonth()+1)}` : '' } ${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`
    },
  },
};
</script>

<style>
</style>